import React from "react";
import { Helmet } from "react-helmet";

const AnalyticsTag = () => {
  return (
    <Helmet>
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-X7S02M92TF"></script>
    <script>
      {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-X7S02M92TF');`}
  </script>
    </Helmet>
  );
};

export default AnalyticsTag;