exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-afspraak-bevestiging-js": () => import("./../../../src/pages/afspraak-bevestiging.js" /* webpackChunkName: "component---src-pages-afspraak-bevestiging-js" */),
  "component---src-pages-boekhouders-js": () => import("./../../../src/pages/boekhouders.js" /* webpackChunkName: "component---src-pages-boekhouders-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-items-js": () => import("./../../../src/pages/faq-items.js" /* webpackChunkName: "component---src-pages-faq-items-js" */),
  "component---src-pages-ib-iframe-js": () => import("./../../../src/pages/ib-iframe.js" /* webpackChunkName: "component---src-pages-ib-iframe-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-overstappen-boekhouder-js": () => import("./../../../src/pages/overstappen-boekhouder.js" /* webpackChunkName: "component---src-pages-overstappen-boekhouder-js" */),
  "component---src-pages-prijzen-js": () => import("./../../../src/pages/prijzen.js" /* webpackChunkName: "component---src-pages-prijzen-js" */),
  "component---src-pages-tools-auto-zakelijk-of-prive-js": () => import("./../../../src/pages/tools/auto-zakelijk-of-prive.js" /* webpackChunkName: "component---src-pages-tools-auto-zakelijk-of-prive-js" */),
  "component---src-pages-tools-inkomstenbelasting-berekenen-js": () => import("./../../../src/pages/tools/inkomstenbelasting-berekenen.js" /* webpackChunkName: "component---src-pages-tools-inkomstenbelasting-berekenen-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-tools-offerte-aangifte-inkomstenbelasting-js": () => import("./../../../src/pages/tools/offerte-aangifte-inkomstenbelasting.js" /* webpackChunkName: "component---src-pages-tools-offerte-aangifte-inkomstenbelasting-js" */),
  "component---src-pages-totsnel-js": () => import("./../../../src/pages/totsnel.js" /* webpackChunkName: "component---src-pages-totsnel-js" */),
  "component---src-pages-vacatures-js": () => import("./../../../src/pages/vacatures.js" /* webpackChunkName: "component---src-pages-vacatures-js" */),
  "component---src-pages-werkwijze-js": () => import("./../../../src/pages/werkwijze.js" /* webpackChunkName: "component---src-pages-werkwijze-js" */),
  "component---src-templates-article-contentful-js": () => import("./../../../src/templates/article-contentful.js" /* webpackChunkName: "component---src-templates-article-contentful-js" */),
  "component---src-templates-nieuws-list-js": () => import("./../../../src/templates/nieuws-list.js" /* webpackChunkName: "component---src-templates-nieuws-list-js" */),
  "component---src-templates-page-contentful-js": () => import("./../../../src/templates/page-contentful.js" /* webpackChunkName: "component---src-templates-page-contentful-js" */)
}

