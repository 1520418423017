import React from "react";
import { Helmet } from "react-helmet";

const Cookiebot = () => {
  return (
    <Helmet>
    <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="c018875a-d48c-4f47-80e8-81e2e22a32e5" data-blockingmode="auto" type="text/javascript"></script>
    </Helmet>
  );
};

export default Cookiebot;


