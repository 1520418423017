// https://coolors.co/palette/f4f1de-e07a5f-3d405b-81b29a-f2cc8f
const backgroundColor = '#fffff5' //Creme
const lightGreen = "#b7d3cd"
const green = '#0f6e5a'

const BookieYellow = '#ffe132'
const lightYellow = '#FFF3A7'

// const Warning =

const Danger = '#ED2648'
const Black = '#171325'

export {
    backgroundColor,
    green,
    BookieYellow,
    lightYellow,
    lightGreen,
    Danger,
    // Warning,
    Black
}
