import {createTheme} from "@mui/material";

import * as Colors from '../theme/colors'

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });


let theme = createTheme()
theme = createTheme(theme, {
  palette: {
    primary: createColor(Colors.green),
    bookieYellow: createColor(Colors.BookieYellow),
    danger: createColor(Colors.Danger),
    bookieGreen: createColor(Colors.green),
    secondary: createColor(Colors.BookieYellow)
  },
  components: {
    // Name of the component
    MuiOutlinedInput: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: 0
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: 0,
          fontStyle: 'italic',
          fontWeight: 700,
          fontFamily: [
          'Poppins',
          'sans-serif'
        ].join(','),
        },
        contained: {
          border: '2px solid black',
        },
        outlined: {
          border: `2px solid ${Colors.green}`,
          '&:hover': {
              border: `2px solid ${Colors.green}`
          },
        }
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: Colors.green
        },
      },
  },
    MuiListItemText: {
        styleOverrides: {
        // Name of the slot
        root: {
          fontFamily: [
            'Poppins',
            'sans-serif'
          ].join(','),
        }
      }
    },
    MuiListItem: {
        styleOverrides: {
        // Name of the slot
        root: {
          fontFamily: [
            'Poppins',
            'sans-serif'
          ].join(','),
        }
      }
    }
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
    color: 'black',
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      letterSpacing: 'normal',
      fontFamily: [
        'Poppins',
        'sans-serif'
      ].join(','),
    },
    body2: {
      fontWeight: 500,
      fontSize: '1rem',
      letterSpacing: 'normal',
      fontFamily: [
        'Poppins',
        'sans-serif'
      ].join(','),
    },
    h1: {
        fontWeight: 700,
        fontSize: '1.5rem',
        [theme.breakpoints.up('md')]: {
          fontSize: '2rem',
        },
        marginTop: 10,
        marginBottom: 10,
        letterSpacing: 'normal',
        textTransform: 'uppercase',
      fontFamily: [
        'grenoble-bold',
      ],
    },
    h2: {
        "fontWeight": 700,
        fontSize: '1.25rem',
        [theme.breakpoints.up('md')]: {
          fontSize: '1.5rem',
        },
        marginTop: 10,
        marginBottom: 10,
        letterSpacing: 'normal',
        fontFamily: [
          'Poppins',
          'sans-serif'
        ].join(','),
    },
    h3: {
    fontSize: '1.2rem',
        [theme.breakpoints.up('md')]: {
          fontSize: '1.3rem',
        },
    fontWeight: 700,
    },
    h4: {
    fontSize: '1.1rem',
        [theme.breakpoints.up('md')]: {
          fontSize: '1.2rem',
        },
    fontWeight: 700,
    },
    h5: {
    "fontSize": '1.0rem',
    fontWeight: 700,
    },
    subtitle2: {
      "fontSize": '1.5rem',
      fontFamily: [
      'Nerko One',
    ],
    },
    caption: {
      "fontSize": '1rem',
      fontFamily: [
      'Nerko One',
    ],
    },
    jumbo: {
      "fontSize": '8rem',
      "color": "white",
      fontWeight: "bold",
      fontFamily: [
      'grenoble',
    ],
    '@media (min-width:0)': {
      fontSize: '3rem',
    },
    '@media (min-width:600px)': {
      fontSize: '4rem',
    },
    '@media (min-width:900px)': {
      fontSize: '6rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '7rem',
    },
    '@media (min-width:1536px)': {
      fontSize: '7rem',
    },
    }
  },
  TranslateLink: {
    color: 'black'
  },
  status: {
    danger: Colors.Danger,
  }
});

export default theme
