/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { ThemeProvider } from '@mui/material/styles';
import theme from "./src/gatsby-theme-material-ui-top-layout/theme";
import MetaPixel from "./src/utils/metaPixel";
import AnalyticsTag from "./src/utils/analyticsTag";
import LinkedinTag from "./src/utils/linkedinTag";
import "./src/components/layout.scss"
import LeadInfoTag from "./src/utils/leadInfoTag";
import Cookiebot from "./src/utils/Cookiebot";
import TikTokTag from "./src/utils/TikTokTag";

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <Cookiebot />

      <AnalyticsTag />
      {/*<LeadInfoTag />*/}
      <MetaPixel />
      <TikTokTag />


      {element}
        <LinkedinTag/>
    </ThemeProvider>
  )
}
